export default class Ablabel {
  constructor({ selector, activeClass }) {
    this.selector = selector;
    this.activeClass = activeClass;

    this.init();
  }

  init() {
    const self = this;
    $(this.selector).each(function () {
      var $this = $(this);
      if ($this.parent().hasClass("input-group")) {
        self.handleInput($this, $this.parent(), $this.val());
      } else {
        self.handleInput($this, $this, $this.val());
      }
    });
    this.setEvents();
  }

  triggerChange(e, self) {
    const $target = $(e.target);

    if ($target.parent().hasClass("input-group")) {
      self.handleInput($target, $target.parent(), $target.val());
    } else {
      self.handleInput($target, $target, $target.val());
    }
  }

  handleInput($el, $relatedEl, val) {
    if (val && val.length > 0 || $el.is("-webkit-autofill")) {
      $relatedEl.addClass(this.activeClass);
    } else {
      $relatedEl.removeClass(this.activeClass);
    }
  }

  setEvents() {
    const $doc = $(document);
    $doc.on("keyup", this.selector, (e) => this.triggerChange(e, this));
    $doc.on("blur", this.selector, (e) => this.triggerChange(e, this));
    $doc.on("focus", this.selector, (e) => this.triggerChange(e, this));
    $doc.on("change", this.selector, (e) => this.triggerChange(e, this));
  }
}
