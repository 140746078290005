function getStorageAvailable() {
  var test = "foo";

  var storage = window.localStorage || window.sessionStorage;

  try {
    storage.setItem(test, test);

    storage.removeItem(test);

    return storage;
  } catch (error) {
    return null;
  }
}

$(function () {
  var page_name = prestashop.page.page_name;

  if (
    typeof page_name != "undefined" &&
    page_name != "index" &&
    page_name != "product"
  ) {
    prestashop.bindGrid();
  }
});

prestashop.bindGrid = function () {
  let storage = false;

  if (typeof getStorageAvailable !== "undefined") {
    storage = getStorageAvailable();
  }

  if (!storage || $("[data-display='grid']").length == 0) {
    return;
  }

  const view = $.totalStorage("display");

  if (view && view != "grid") {
    prestashop.displayType(view);
  } else {
    $("[data-display='grid']").addClass("active");
  }

  $(document).on("click", "[data-toggle='display']", function (e) {
    e.preventDefault();
    const $target = $(e.target).closest("a");
    const display = $target.data("display");

    if ($target.hasClass("active")) {
      return;
    }

    prestashop.displayType(display);
  });
};

prestashop.displayType = function (view) {
  if (view == "list") {
    $("#js-product-list .products").removeClass("grid").addClass("list");

    $(".products article").each(function (index, element) {
      const $el = $(element);
      const classes = $el.attr("class").split(/\s+/);

      const gridClasses = classes
        .filter(function (ele) {
          if (ele.slice(0, 3) == "col") {
            return ele;
          }
        })
        .join(" ");

      $el
        .removeClass(gridClasses)
        .addClass("col-xs-12 product-miniature--list")
        .attr("data-grid", gridClasses);

      let html = "";
      html += '<div class="product-miniature__container">';
      html += '<div class="row product-miniature__row">';

      html +=
        '<div class="product-miniature__block product-miniature__block--img">';
      html += `<div class='product-miniature__thumb'>${$el
        .find(".product-miniature__thumb")
        .html()}</div>`;
      html += "</div>";

      html +=
        '<div class="product-miniature__block product-miniature__block--content">';
      html += '<div class="product-miniature__list-head">';

      html += `<h2 class="product-miniature__title">${$el
        .find(".product-miniature__title")
        .html()}</h2>`;

      const productStars = $el.find(".product-miniature__stars").html();
      if (productStars) {
        html += `<div class="product-miniature__stars">${productStars}</div>`;
      }

      html += "</div>";

      const productDesc = $el.find(".product-miniature__desc").html();
      const productFeatures = $el.find(".feature-list").html();
      if (productDesc) {
        html += `<p class="product-miniature__desc">${productDesc}</p>`;
      }
      if (productFeatures) {
        html += `<ul class="feature-list feature-list--listing">${productFeatures}</ul>`;
      }
      html += "</div>";

      html +=
        '<div class="product-miniature__block product-miniature__block--right">';

      const productPrices = $el.find(".product-miniature__prices").html();
      const productBottom = $el.find(".product-miniature__bottom").html();
      const productFlags = $el.find(".product-flags").wrap('<div></div>').parent().html();
      const productQuantities = $el.find(".product-quantities").wrap('<div></div>').parent().html();
      if (productFlags) {
        html += productFlags;
      }
      if (productPrices) {
        html += `<div class="product-miniature__prices">${productPrices}</div>`;
      }
      if (productQuantities) {
        html += productQuantities;
      }
      if (productBottom) {
        html += `<div class="product-miniature__bottom">${productBottom}</div>`;
      }
      html += "</div>";

      html += `<div class="product-miniature__hover-bar">${$el
        .find(".product-miniature__hover-bar")
        .html()}</div>`;

      html += "</div>";
      html += "</div>";

      $el.html(html);
    });
  } else {
    $("#js-product-list .products").removeClass("list").addClass("grid");

    $(".products article").each(function (index, element) {
      const $el = $(element);
      const productPrices = $el.find(".product-miniature__prices").html();
      const productBottom = $el.find(".product-miniature__bottom").html();
      const productFlags = $el.find(".product-flags").wrap('<div></div>').parent().html();
      const productDesc = $el.find(".product-miniature__desc").html();
      const productFeatures = $el.find(".feature-list").html();
      const productQuantities = $el.find(".product-quantities").wrap('<div></div>').parent().html();

      $el
        .removeClass("col-xs-12 product-miniature--list")
        .addClass($el.attr("data-grid"));

      let html = "";
      if (productFlags) {
        html += productFlags;
      }
      html += '<div class="product-miniature__container">';

      html += '<div class="product-miniature__inner">';

      html += `<div class='product-miniature__thumb'>${$el
        .find(".product-miniature__thumb")
        .html()}</div>`;

      html += `<h2 class="product-miniature__title">${$el
        .find(".product-miniature__title")
        .html()}</h2>`;

      if (productDesc) {
        html += `<p class="product-miniature__desc">${productDesc}</p>`;
      }
      if (productQuantities) {
        html += productQuantities;
      }
      if (productPrices) {
        html += `<div class="product-miniature__prices">${productPrices}</div>`;
      }
      if (productFeatures) {
        html += `<ul class="feature-list">${productFeatures}</ul>`;
      }

      html += `<div class="product-miniature__hover-bar">${$el
        .find(".product-miniature__hover-bar")
        .html()}</div>`;

      html += "</div>";

      if (productBottom) {
        html += `<div class="product-miniature__bottom">${productBottom}</div>`;
      }
      html += "</div>";

      $el.html(html);
    });
  }

  $.totalStorage("display", view);
  $('[data-toggle="display"]').removeClass("active");
  $('[data-display="' + view + '"]').addClass("active");

  prestashop.emit("listDisplayChange", view);
};
