export default function FetchThenExecute({ selector, files, fnc }) {
  this.selector = selector;
  this.files = files;
  this.fnc = fnc;
  this.elems = $(this.selector);
  this.libraryLoaded = false;

  const self = this;

  this.observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        const { target } = entry;

        self.observer.unobserve(target);

        if (!self.libraryLoaded) {
          Promise.all(self.files())
            .then(() => {
              self.fnc(target);
              self.libraryLoaded = true;
            })
            .catch(({ message }) => {
              console.error(message);
            });
        } else {
          self.fnc(target);
        }
      }
    });
  });

  this.observe(this.elems);
}

FetchThenExecute.prototype.observe = function (elems) {
  const self = this;
  elems.each((i, el) => {
    self.observer.observe(el);
    $(el).addClass("observe-elem");
  });
};

FetchThenExecute.prototype.update = function () {
  const elems = $(this.selector).not(".observe-elem");
  this.observe(elems);
};
