import FetchThenExecute from "../utils/fetchThenExecute";

$(() => {
  fetchCountdown();
});

function fetchCountdown() {
  const initCountdown = new FetchThenExecute({
    selector: ".js-product-countdown",
    files: () => [
      import("../../css/components/_product-countdown.scss"),
      import("../lib/jquery.countdown.min.js"),
    ],
    fnc: initSpecialsCountdown,
  });
}

function initSpecialsCountdown(el) {
  const $el = $(el);
  const date = $el.data("date");
  const labelDays = $el.data("days");
  const labelHours = $el.data("hours");
  const labelMin = $el.data("min");
  const labelSec = $el.data("sec");

  $el.countdown(date, function (event) {
    if (event.offset.totalSeconds === 0) {
      location.reload();
    }

    const days =
      '<div class="product-countdown__elem"><div class="product-countdown__item"><div class="product-countdown__value">%D</div><div class="product-countdown__label">' +
      labelDays +
      "</div></div></div>";
    const hours =
      '<div class="product-countdown__elem"><div class="product-countdown__item"><div class="product-countdown__value">%H</div><div class="product-countdown__label">' +
      labelHours +
      "</div></div></div>";
    const min =
      '<div class="product-countdown__elem"><div class="product-countdown__item"><div class="product-countdown__value">%M</div><div class="product-countdown__label">' +
      labelMin +
      "</div></div></div>";
    const sec =
      '<div class="product-countdown__elem"><div class="product-countdown__item"><div class="product-countdown__value">%S</div><div class="product-countdown__label">' +
      labelSec +
      "</div></div></div>";

    $(this).html(event.strftime(days + hours + min + sec));
  });
}
