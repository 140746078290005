import FetchThenExecute from "../utils/fetchThenExecute";

$(() => {
  initMap();
});

function initMap() {
  if ($("#map").length === 0) {
    return;
  }

  const map = new FetchThenExecute({
    selector: "#map",
    files: () => [
      import("../../css/lib/leaflet.scss"),
      import("../lib/leaflet.js"),
    ],
    fnc: setMap,
  });
}

function setMap() {
  const map = L.map("map").setView(
    [storesmap.centerlat, storesmap.centerlng],
    storesmap.zoom
  );

  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    attribution: "",
  }).addTo(map);

  const icon = L.divIcon({ className: "store-map__marker" });

  $.each(storesmap.stores, function (i, store) {
    L.marker([store.latitude, store.longitude], { icon }).addTo(map);
  });
}
