export default class CircularProgressBar {
  constructor(options) {
    this.options = options;

    const { pie, percent, color, strokeWidth, opacity, number, size, fontSize, fontWeight, fontColor } = this.options;
    this.pie = pie;
    this.pieElement = document.querySelector(`.${pie}`);
    this.percent = percent || 0;
    this.color = color || '#00a1ff';
    this.strokeWidth = strokeWidth || 10;
    this.opacity = opacity || 0.1;
    this.number = number || true;
    this.size = size || 200;
    this.fontSize = fontSize || '3rem';
    this.fontWeight = fontWeight || 700;
    this.fontColor = fontColor || '#365b74';
    this.end = 264;

    if (this.pieElement) {
      this.createSvg();
    }
  }

  hexTorgb(fullhex) {
    const hex = fullhex.substring(1, 7);
    const rgb = `${parseInt(hex.substring(0, 2), 16)}, ${parseInt(hex.substring(2, 4), 16)},${parseInt(hex.substring(4, 6), 16)}`;
    return rgb;
  }

  circularProgressBar() {
    let stroke = document.querySelector(`.${this.pie}-stroke`);


    for (let i = 0; i <= this.end; i++) {
      setTimeout(() => {
        if (i > this.percent) return;
        let d = parseInt(i * 2.64);
        stroke.setAttribute('style', `fill: transparent; stroke: ${this.color}; stroke-width: ${this.strokeWidth}; stroke-dashoffset: 66; stroke-dasharray: ${d} ${this.end - d}`);
      }, i * 1);
    }

    this.pieElement.setAttribute('style', `position: relative; border-radius: 50%; width: ${this.size}px; height: ${this.size}px;`);

  }

  createSvg() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');

    svg.setAttribute('width', this.size);
    svg.setAttribute('height', this.size);
    svg.setAttribute('viewBox', '0 0 100 100');
    svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");

    circle.setAttribute('cx', 50);
    circle.setAttribute('cy', 50);
    circle.setAttribute('r', 48);
    circle.setAttribute('class', `${this.pie}-stroke`);

    svg.appendChild(circle);
    this.pieElement.appendChild(svg)

    this.circularProgressBar()
  }

}