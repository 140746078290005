import FetchThenExecute from "../utils/fetchThenExecute";
import PageSlider from "./page-slider";

$(() => {
  fetchSliders();
});

function fetchSliders() {
  const initSliders = new FetchThenExecute({
    selector: ".theme-slider",
    files: () => [
      import("../../css/components/slick-theme.scss"),
      import("../../css/components/slick.scss"),
      import("../lib/slick.min.js"),
    ],
    fnc: initSlider,
  });

  prestashop.sliders = initSliders;

  prestashop.on("updatedProduct", () => {
    prestashop.sliders.update();
  });
}

function initSlider(el) {
  const $elem = $(el);

  if ($elem.hasClass("products")) {
    initProductsSlider($elem);
  } else if ($elem.hasClass("home-slider__slider-list")) {
    initHomeSlider($elem);
  } else if ($elem.hasClass("productThumbs")) {
    initProductThumbsSlider($elem);
  } else if ($elem.hasClass("js-modal-product-images")) {
    initModalImgSlider($elem);
  } else if ($elem.hasClass("blog-slider")) {
    intBlogSlider($elem);
  } else if ($elem.hasClass("slider-manuf")) {
    intManufSlider($elem);
  } else if ($elem.hasClass("testimonials__list")) {
    intTestimonialsSlider($elem);
  } else if ($elem.hasClass("js-product-special-block-slider")) {
    initProductSpecialsSlider($elem);
  }
}

function initProductsSlider($el) {
  const config = {
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
    arrow: false,
    speed: 600,
    autoplay: false,
  };

  let responsive = [
    {
      breakpoint: 1320,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ];

  if (
    $el.closest(".featured-products").hasClass("featured-products--specials")
  ) {
    config.slidesToShow = 4;
    config.slidesToScroll = 4;

    responsive = [
      {
        breakpoint: 1605,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ];
  }

  const slider = new PageSlider({
    $element: $el,
    customArrow: true,
    sliderContainer: ".featured-products",
    customArrowSelectorNext: ".slider-arrows__btn--right",
    customArrowSelectorPrev: ".slider-arrows__btn--left",
    arrowContainerSelector: ".slider-arrows",
    sliderConfig: { ...config, responsive },
    handleSliderLink: true,
  });
}

function initModalImgSlider($el) {
  $el.slick({
    infinite: false,
    arrow: false,
    speed: 300,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function intManufSlider($el) {
  const slider = new PageSlider({
    $element: $el,
    customArrow: true,
    sliderContainer: ".manuf-slider",
    customArrowSelectorNext: ".slider-arrows__btn--right",
    customArrowSelectorPrev: ".slider-arrows__btn--left",
    arrowContainerSelector: ".slider-arrows",
    sliderConfig: {
      infinite: false,
      speed: 600,
      autoplay: false,
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 1320,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    },
  });
}

function intBlogSlider($el) {
  const slider = new PageSlider({
    $element: $el,
    customArrow: true,
    sliderContainer: ".recent-posts",
    customArrowSelectorNext: ".slider-arrows__btn--right",
    customArrowSelectorPrev: ".slider-arrows__btn--left",
    arrowContainerSelector: ".slider-arrows",
    sliderConfig: {
      infinite: false,
      speed: 600,
      autoplay: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  });
}

function initHomeSlider($el) {

  const $parent = $('#homeSlider');
  const infinite = $parent.attr('data-wrap') === 'true';
  const autoplaySpeed = parseInt($parent.attr('data-interval'), 10);

  const slider = new PageSlider({
    $element: $el,
    customArrow: true,
    customDots: true,
    dotsContainer: ".home-slider__dots",
    sliderContainer: ".home-slider",
    customArrowSelectorNext: ".slider-arrows__btn--right",
    customArrowSelectorPrev: ".slider-arrows__btn--left",
    arrowContainerSelector: ".home-slider__navigantion",
    sliderConfig: {
      infinite,
      arrow: true,
      speed: 600,
      autoplay: true,
      autoplaySpeed,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  });
}

function intTestimonialsSlider($el) {
  const slider = new PageSlider({
    $element: $el,
    customArrow: true,
    sliderContainer: ".testimonials",
    customArrowSelectorNext: ".slider-arrows__btn--right",
    customArrowSelectorPrev: ".slider-arrows__btn--left",
    arrowContainerSelector: ".slider-arrows",
    sliderConfig: {
      infinite: true,
      arrow: true,
      speed: 600,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  });
}

function initProductThumbsSlider($el) {
  const slider = new PageSlider({
    $element: $el,
    sliderConfig: {
      infinite: false,
      arrow: true,
      speed: 600,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    },
  });
}

function initProductSpecialsSlider($el) {
  const slider = new PageSlider({
    $element: $el,
    sliderConfig: {
      infinite: false,
      arrows: false,
      dots: true,
      speed: 600,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1
    },
  });
}
