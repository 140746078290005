export default class PageSlider {
  constructor({
    customArrow = false,
    sliderContainer = null,
    customArrowSelectorNext = null,
    customArrowSelectorPrev = null,
    arrowContainerSelector = null,
    customDots = false,
    dotsContainer = null,
    sliderConfig = null,
    $element = null,
    handleSliderLink = false,
  }) {
    this.customArrow = customArrow;
    this.sliderContainer = sliderContainer;
    this.customArrowSelectorNext = customArrowSelectorNext;
    this.customArrowSelectorPrev = customArrowSelectorPrev;
    this.arrowContainerSelector = arrowContainerSelector;
    this.customDots = customDots;
    this.dotsContainer = dotsContainer;
    this.sliderConfig = sliderConfig;
    this.$element = $element;
    this.handleSliderLink = handleSliderLink;

    this.init();
  }

  areCustomArrowsSelectorsSet() {
    return (
      this.customArrow &&
      this.customArrowSelectorNext &&
      this.sliderContainer &&
      this.customArrowSelectorPrev &&
      this.arrowContainerSelector
    );
  }

  areCustomDotsSelectorsSet() {
    return this.dotsContainer && this.customDots;
  }

  setHeightForSliderLink($el) {
    const $sliderLink = $el.find(".link-miniature");
    const prodHeight = $el
      .find(".product-miniature__container")
      .first()
      .outerHeight();
    $sliderLink.css({
      height: prodHeight + "px",
    });
  }

  init() {
    if (!this.sliderConfig || !this.$element) {
      throw new Error("$element and sliderconfig are required");
    }

    let config = this.sliderConfig;
    const $el = this.$element;
    const $partentContainer = $el.closest(this.sliderContainer);

    if (this.areCustomArrowsSelectorsSet()) {
      const prevArrow = $partentContainer.find(this.customArrowSelectorPrev);
      const nextArrow = $partentContainer.find(this.customArrowSelectorNext);

      config = {
        ...config,
        prevArrow,
        nextArrow,
      };
    }

    $el.on("init", () => {
      if (this.areCustomArrowsSelectorsSet()) {
        const $navgiationContainer = $partentContainer.find(
          this.arrowContainerSelector
        );
        $navgiationContainer.show();
      }
      if (this.areCustomDotsSelectorsSet()) {
        const $dots = $el.find(".slick-dots").detach();
        const $navgiationContainer = $partentContainer.find(this.dotsContainer);

        $dots.appendTo($navgiationContainer);
      }
      if (this.handleSliderLink) {
        this.setHeightForSliderLink($el);
      }
    });

    if (this.handleSliderLink) {
      $el.on("afterChange", () => {
        this.setHeightForSliderLink($el);
      });
    }

    this.$element.slick(config);
  }
}
